import { ConsultationProps } from 'templates/pricing';
import { PricingCardConsultationsProps } from './pricing';

export const consultationMapper = (
  consultation: ConsultationProps[] | null,
): PricingCardConsultationsProps => {
  const defaultState = { doctor: false, nurse: false, therapist: false };
  return consultation
    ? consultation.reduce((acc, curr) => {
        if (acc[curr] === false) {
          acc[curr] = true;
        }
        return acc;
      }, defaultState)
    : defaultState;
};
