import { PageContent_acfSharedPage } from 'queries/gql_types/PageContent';
import * as React from 'react';
import { PrimaryButtonInverted } from 'ui/components/atoms/button/button';
import { Divider } from 'ui/components/atoms/divider/divider';
import {
  Spacing,
  TextPriority4,
  TextSmall,
  TextSub3,
} from 'ui/components/atoms/typography/typography';
import styles from './modal.module.css';

type Props = {
  preheading?: string;
  heading: string;
  description: string;
  button: string;
};

type ModalState = {
  isVisible: boolean;
};

type ModalActions = {
  type: 'TOGGLE';
};

const initialState: ModalState = { isVisible: true };

function reducer(state: ModalState, action: ModalActions) {
  switch (action.type) {
    case 'TOGGLE':
      return { isVisible: !state.isVisible };
    default:
      throw new Error();
  }
}

const createModal = ({ heading, preheading, description, button }: Props) => {
  const [store, dispatch] = React.useReducer(reducer, initialState);

  return {
    Modal: () =>
      store.isVisible ? (
        <div className={styles.container}>
          <div className={styles.modal}>
            <Divider />
            {preheading && (
              <TextSub3
                tagName="div"
                className={styles.preheading}
                bottomSpacing={Spacing.SPACING_8}>
                {preheading}
              </TextSub3>
            )}
            <TextPriority4 tagName="div">{heading}</TextPriority4>
            <TextSmall tagName="div" bottomSpacing={Spacing.SPACING_24}>
              {description}
            </TextSmall>
            <PrimaryButtonInverted
              className={styles.button}
              onClick={() => dispatch({ type: 'TOGGLE' })}>
              {button}
            </PrimaryButtonInverted>
          </div>
        </div>
      ) : (
        <></>
      ),
    store,
  };
};

export const createRestrictedModal = ({
  acfSharedPage,
}: {
  acfSharedPage: PageContent_acfSharedPage | null;
}) => {
  const restricted =
    acfSharedPage != null && acfSharedPage.globalPageSettings != null
      ? acfSharedPage.globalPageSettings.restricted
      : [];

  if (restricted == null || !restricted.length) {
    return {
      RestrictedModal: () => <></>,
      store: { isVisible: false },
    };
  }

  const { description, heading, preheading, button } = restricted[0];

  const { Modal: RestrictedModal, store } = createModal({
    description,
    heading,
    button,
    preheading: preheading ? preheading : undefined,
  });

  return {
    RestrictedModal,
    store,
  };
};
