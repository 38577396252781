import React, { useState } from 'react';
import classNames from 'classnames';
import { PageTemplateProps } from 'shared/types/pages';
import { BannerPricing } from 'ui/components/molecules/banner/banner_pricing';
import { Tabs } from 'ui/components/molecules/tabs/tabs';
import { Container } from 'ui/components/organisms/container/container';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import { Spacing, TextPriority4, TextRegularAlt } from 'ui/components/atoms/typography/typography';
import { HtmlBreaks } from 'util/html';
import { Shape, Shapes } from 'ui/components/atoms/shapes/shapes';
import { PricingTab } from './pricing_tab';
import styles from './pricing.module.css';
import { PagePricingProps } from 'templates/pricing';
import { consultationMapper } from './pricing_helpers';
import { createRestrictedModal } from 'ui/components/molecules/modal/modal';
import { Main } from 'ui/components/organisms/main/main';
import { WYSIWYG } from 'ui/components/molecules/wysiwyg/wysiwyg';
import { JsonLD } from 'ui/components/molecules/json_ld/json_ld';

export type PricingCardConsultationsProps = {
  doctor: boolean;
  nurse: boolean;
  therapist: boolean;
};

type PricingCardProps = {
  heading: string;
  description?: string;
  isActive?: boolean;
  items: {
    subheading?: string;
    procedures: string;
    description: string;
    consultation: PricingCardConsultationsProps;
  }[];
};

export const TooltipValues = {
  nurse: 'Cosmetic nurse consultation required',
  doctor: 'Cosmetic doctor consultation required',
  therapist: 'Dermal therapist consultation required',
} as const;

export const PricingCard = ({ heading, items, isActive, description }: PricingCardProps) => (
  <div className={classNames(styles.pricingCard, { [styles.pricingCardActive]: isActive })}>
    <Row>
      <Col sm={12} lg={3}>
        <TextPriority4 className={styles.priceRowHeading}>{heading}</TextPriority4>
      </Col>
      <Col sm={12} lg={8} lgOffset={1}>
        {items.map((x, i) => {
          return (
            <React.Fragment key={i}>
              <Row className={styles.priceRow}>
                <Col sm={5} md={6}>
                  {x.subheading && (
                    <div className={styles.shapes}>
                      <TextRegularAlt className={styles.shapesHeading}>
                        {x.subheading}
                      </TextRegularAlt>
                      <Shapes items={x.consultation} />
                    </div>
                  )}
                  {React.Children.map(
                    HtmlBreaks(x.procedures, { includeBreaks: false }),
                    (child, index) => {
                      return (
                        <React.Fragment>
                          {index === 0 && !x.subheading ? (
                            <div className={styles.shapes}>
                              <TextRegularAlt className={styles.shapesHeading}>
                                <strong>{child}</strong>
                              </TextRegularAlt>
                              <Shapes items={x.consultation} />
                            </div>
                          ) : (
                            <TextRegularAlt bottomSpacing={Spacing.SPACING_8}>
                              <strong>{child}</strong>
                            </TextRegularAlt>
                          )}
                        </React.Fragment>
                      );
                    },
                  )}
                </Col>
                <Col sm={7} md={6}>
                  <TextRegularAlt>{HtmlBreaks(x.description)}</TextRegularAlt>
                </Col>
              </Row>
              {i !== items.length - 1 && <div className={styles.hr}></div>}
            </React.Fragment>
          );
        })}
        {description && (
          <span className={styles.priceSubheading}>
            <WYSIWYG html={description} maxWidth={false} />
          </span>
        )}
      </Col>
    </Row>
  </div>
);

const createConsultations = ({
  heading,
  description,
  types,
}: {
  heading: string;
  description: string;
  types: {
    type: 'doctor' | 'nurse' | 'therapist';
    heading: string;
    description: string;
  }[];
}) => ({ isActive }: { isActive?: boolean }) => (
  <div className={classNames(styles.pricingCard, { [styles.pricingCardActive]: isActive })}>
    <Row>
      <Col sm={12} lg={3}>
        <TextPriority4 className={styles.priceRowHeading}>{heading}</TextPriority4>
      </Col>
      <Col sm={12} lg={8} lgOffset={1}>
        {types.map((x, i) => (
          <React.Fragment key={i}>
            <Row className={styles.priceRow}>
              <Col sm={5} md={6}>
                <Shape type={x.type} className={styles.consultationShape} />
                <TextRegularAlt tagName="span">
                  <strong>{x.heading}</strong>
                </TextRegularAlt>
              </Col>
              <Col sm={7} md={6}>
                <TextRegularAlt>{HtmlBreaks(x.description)}</TextRegularAlt>
              </Col>
            </Row>
            <div className={styles.hr}></div>
          </React.Fragment>
        ))}
        <div className={styles.priceRow}>
          <TextRegularAlt>{description}</TextRegularAlt>
        </div>
      </Col>
    </Row>
  </div>
);

export const Pricing = ({ data }: PageTemplateProps<PagePricingProps>) => {
  const { banner, treatments, consultations } = data.wpPage.acfTemplatePricing.pricing;
  const { breadcrumbs } = data.wpPage.seo;
  const tabs = treatments.map(x => {
    /**
     * Convert the treatmentTypes consultations from
     * an array of strings: ['nurse', 'doctor'] to an object
     * e.g. { nurse: true, doctor: true, therapist: false }
     */
    const treatmentTypes = x.treatmentTypes.map(type => {
      return {
        name: type.name,
        ...(type.description ? { description: type.description } : undefined),
        items:
          type.items == null
            ? []
            : type.items.map(item => ({
                ...item,
                consultation: consultationMapper(item.consultation),
              })),
      };
    });
    return { label: x.name, hideConsultations: x.hideConsultations ?? false, treatmentTypes };
  });
  const [activeIndex, setActive] = useState(0);
  const renderConsultation = createConsultations({
    heading: consultations.heading,
    description: consultations.description,
    types: consultations.types,
  });
  const { RestrictedModal, store } = createRestrictedModal({
    acfSharedPage: data.wpPage.acfSharedPage,
  });

  return (
    <>
      <Main isBlurred={store.isVisible}>
        <JsonLD
          title={data.wpPage.title}
          acfSharedPage={data.wpPage.acfSharedPage}
          breadcrumbs={breadcrumbs}
        />
        <BannerPricing
          heading={banner.heading}
          description={banner.description}
          image={banner.image}
        />
        <Container>
          <div className={styles.scroller}>
            <Tabs
              className={styles.scrollerContents}
              items={tabs}
              onClick={(i: number) => setActive(i)}
              activeIndex={activeIndex}
            />
          </div>
        </Container>
        <div className={styles.section}>
          {tabs.map((tab, i) => (
            <PricingTab
              isActive={activeIndex === i}
              key={i}
              treatmentTypes={tab.treatmentTypes ? tab.treatmentTypes : []}
              Consultations={!tab.hideConsultations ? renderConsultation : undefined}
            />
          ))}
        </div>
      </Main>

      <RestrictedModal />
    </>
  );
};
