import React, { useReducer } from 'react';
import classNames from 'classnames';
import { Container } from 'ui/components/organisms/container/container';
import { PricingCard } from 'ui/components/pages/pricing/pricing';
import { Filter } from 'ui/components/molecules/filters/filters';
import styles from './pricing_tab.module.css';
import pricingStyles from './pricing.module.css';

type PricingTab = {
  isActive: boolean;
  Consultations?: (props: { isActive: boolean }) => JSX.Element;
  treatmentTypes: {
    name: string;
    description?: string;
    items: {
      subheading?: string;
      procedures: string;
      description: string;
      consultation: {
        doctor: boolean;
        nurse: boolean;
        therapist: boolean;
      };
    }[];
  }[];
};

type PricingTabState = {
  isSelected: boolean;
}[];

type PricingTabActions =
  | {
      type: 'TOGGLE';
      index: number;
    }
  | { type: 'SELECT_ALL' };

/**
 * Create initial state which is the array + plus for 'All'
 *
 * @param items Array
 */
const createInitialState = (items: PricingTab['treatmentTypes'], offset = 1): PricingTabState => {
  return new Array(items.length + offset).fill({ isSelected: true });
};

function reducer(state: PricingTabState, action: PricingTabActions) {
  switch (action.type) {
    case 'TOGGLE':
      return [
        ...state.slice(0, action.index).fill({ isSelected: false }),
        { isSelected: true },
        ...state.slice(action.index + 1).fill({ isSelected: false }),
      ];
    case 'SELECT_ALL':
      return state.map(() => ({ isSelected: true }));
    default:
      throw new Error();
  }
}

export const PricingTab = ({ isActive, treatmentTypes, Consultations }: PricingTab) => {
  // Conditionally disable offset for Consultations
  const offset = Consultations ? 1 : 0;
  const [state, dispatch] = useReducer(reducer, createInitialState(treatmentTypes, offset));

  const cards = [
    ...(Consultations
      ? [
          {
            Card: function Card() {
              return <Consultations isActive={state[0].isSelected} />;
            },
            label: 'Consultations',
          },
        ]
      : []),
    ...treatmentTypes.map((x, i) => {
      const Card = () => (
        <PricingCard
          isActive={state[i + offset].isSelected}
          heading={x.name}
          description={x.description}
          items={x.items}
        />
      );
      return {
        Card,
        label: x.name,
      };
    }),
  ];

  const handleFilterClick = (i: number) => () => dispatch({ type: 'TOGGLE', index: i });
  const handleAllClick = () => dispatch({ type: 'SELECT_ALL' });
  const isAllTrue = state.every(x => x.isSelected);

  return (
    <div className={classNames(styles.pricingTab, { [styles.pricingTabActive]: isActive })}>
      <Container>
        <div className={pricingStyles.scroller}>
          <div className={classNames(pricingStyles.scrollerContents, styles.filters)}>
            {cards.length > 1 ? (
              <>
                <Filter
                  onClick={handleAllClick}
                  label="All"
                  isActive={isAllTrue}
                  className={styles.filtersItem}
                />
                {cards.map((x, i) => (
                  <Filter
                    label={x.label}
                    isActive={!isAllTrue && state[i].isSelected}
                    key={i}
                    onClick={handleFilterClick(i)}
                    className={styles.filtersItem}
                  />
                ))}
              </>
            ) : null}
          </div>
        </div>
        <div className={pricingStyles.scroller}>
          <div className={classNames(pricingStyles.scrollerContents, styles.cardContainer)}>
            {cards.map(({ Card }, i) => {
              return <Card key={i} />;
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};
