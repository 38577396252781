import * as React from 'react';
import { TextSmall } from '../typography/typography';
import styles from './tooltip.module.css';

type TooltipProps = {
  label: string;
};

export const Tooltip = ({ label }: TooltipProps) => (
  <div className={styles.container}>
    <TextSmall>
      <strong>{label}</strong>
    </TextSmall>
  </div>
);
