import * as React from 'react';
import classNames from 'classnames';
import { TextSub2 } from 'ui/components/atoms/typography/typography';
import styles from './tabs.module.css';

type TabProps = {
  label: string;
  onClick?: () => void;
};

const Tab = ({
  label,
  className,
  onClick,
  isActive,
}: TabProps & { isActive?: boolean; className?: string }) => (
  <div
    onClick={onClick}
    className={classNames(styles.tab, { [styles.isActive]: isActive }, className)}>
    <TextSub2 tagName="div">{label}</TextSub2>
  </div>
);

type TabsProps = {
  items: TabProps[];
  onClick?: (i: number) => void;
  className?: string;
  activeIndex: number;
};

const TabsComponent = ({ items, onClick, className, activeIndex }: TabsProps) => (
  <div className={classNames(styles.tabs, className)}>
    {items.map((x, i) => (
      <Tab
        onClick={() => {
          if (onClick) {
            onClick(i);
          }
        }}
        className={styles.tabsItem}
        key={i}
        label={x.label}
        isActive={activeIndex === i}
      />
    ))}
  </div>
);

export const Tabs = React.memo(TabsComponent);
