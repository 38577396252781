import * as React from 'react';
import classNames from 'classnames';
import { TextSub3 } from 'ui/components/atoms/typography/typography';
import styles from './filters.module.css';

type FilterProps = {
  label: string;
  onClick?: () => void;
};

export const Filter = ({
  label,
  onClick,
  isActive,
  className,
}: FilterProps & { isActive?: boolean; className?: string }) => (
  <div
    onClick={onClick}
    className={classNames(styles.filter, { [styles.isActive]: isActive }, className)}>
    <TextSub3>{label}</TextSub3>
  </div>
);
