import { graphql } from 'gatsby';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { Pricing } from 'ui/components/pages/pricing/pricing';

export const query = graphql`
  query PricingPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...PageContent
      acfTemplatePricing {
        pricing {
          banner {
            description
            heading
            image {
              localFile {
                ...FluidImageFull
              }
            }
          }
          consultations {
            description
            heading
            types {
              description
              heading
              type
            }
          }
          treatments {
            name
            hideConsultations
            treatmentTypes {
              name
              description
              items {
                description
                procedures
                subheading
                consultation
              }
            }
          }
        }
      }
    }
    wpGlobal: wp {
      ...WpGlobal
    }
  }
`;

export type ConsultationProps = 'doctor' | 'nurse' | 'therapist';

export type PagePricingProps = {
  acfTemplatePricing: {
    pricing: {
      banner: {
        heading: string;
        description: string;
        image: null | WPImageFluid;
      };
      consultations: {
        description: string;
        heading: string;
        types: {
          description: string;
          heading: string;
          type: ConsultationProps;
        }[];
      };
      treatments: {
        name: string;
        hideConsultations: boolean | null;
        treatmentTypes: {
          name: string;
          description: string;
          items:
            | {
                subheading?: string;
                procedures: string;
                description: string;
                consultation: null | ConsultationProps[];
              }[]
            | null;
        }[];
      }[];
    };
  };
};

export default Pricing;
