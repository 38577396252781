import * as React from 'react';
import classNames from 'classnames';
import styles from './shapes.module.css';
import { Tooltip } from 'ui/components/atoms/tooltip/tooltip';
import { TooltipValues } from 'ui/components/pages/pricing/pricing';

type Shape = {
  type: 'doctor' | 'nurse' | 'therapist';
  className?: string;
};

export const Shape = ({ type, className }: Shape) => (
  <div
    className={classNames(
      styles.shape,
      {
        [styles.circle]: type === 'doctor',
        [styles.square]: type === 'nurse',
        [styles.triangle]: type === 'therapist',
      },
      className,
    )}></div>
);

type ShapesProps = {
  items: {
    doctor: boolean;
    nurse: boolean;
    therapist: boolean;
  };
};

export const Shapes = ({ items }: ShapesProps) => {
  const shapes: Shape[] = Object.keys(items)
    .filter(x => {
      const item = items[x as Shape['type']];
      return item === true;
    })
    .map(x => {
      return { type: x } as Shape;
    });
  return (
    <div className={styles.shapes}>
      {shapes.map((x, i) => (
        <div key={i} className={styles.shapesItem}>
          <Shape type={x.type} />
          <Tooltip label={TooltipValues[x.type]} />
        </div>
      ))}
    </div>
  );
};
