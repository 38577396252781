import * as React from 'react';
import { Container } from 'ui/components/organisms/container/container';
import { TextPriority2, TextRegularAlt, Spacing } from 'ui/components/atoms/typography/typography';
import { Row, Col } from 'ui/components/organisms/grid/grid';
import styles from './banner_pricing.module.css';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { Picture } from 'ui/components/atoms/picture/picture';
import { HtmlBreaks } from 'util/html';

export type BannerPricingProps = {
  heading: string;
  description: string;
  image: null | WPImageFluid;
};

export const BannerPricing = ({ image, description, heading }: BannerPricingProps) => {
  return (
    <div className={styles.banner}>
      <Container>
        <Row middle="md">
          <Col sm={12} md={6} lg={5} lgOffset={1}>
            <div className={styles.textContent}>
              <TextPriority2 tagName="h1" bottomSpacing={Spacing.SPACING_16}>
                {heading}
              </TextPriority2>
              <TextRegularAlt bottomSpacing={Spacing.SPACING_24}>
                {HtmlBreaks(description)}
              </TextRegularAlt>
            </div>
          </Col>
          <Col className={styles.imageColumn} sm={12} md={6} lg={5}>
            <Picture
              type="fluid"
              fluid={image?.localFile.childImageSharp.fluid}
              placeholder="285x190"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
