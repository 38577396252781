import classNames from 'classnames';
import * as React from 'react';
import styles from './main.module.css';

export const Main = ({
  children,
  isBlurred = false,
  className,
}: {
  children: any;
  isBlurred?: boolean;
  className?: string;
}) => {
  return (
    <main
      className={classNames(
        {
          [styles.blur]: isBlurred,
        },
        className,
      )}>
      {children}
    </main>
  );
};
